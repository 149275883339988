<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  // created() {
  //   document.title = 'Справочник'
  // },
}
</script>
